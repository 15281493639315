@import 'styles/vars';

* {
  margin: 0;
}

b {
  font-weight: 500;
}

// TODO: width: 100% and margin: 5px 0 should be here, maybe.
input[type='text'],
input[type='email'],
input[type='password'],
select,
textarea {
  background: #fff;
  border: 2px solid $dark;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  height: 40px;
  display: inline-block;
  border-radius: 0;

  &:read-only {
    // NOP, because of the Calendars
    // cursor: not-allowed;
  }
}

input[type='checkbox'] {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

textarea {
  line-height: 26px;
  resize: none;
}

hr,
.hr {
  border: none;
  border-bottom: 1px solid $color-primary;
}

h1,
.h1 {
  line-height: normal;
  font-size: 40px;
  font-weight: 300;

  @media (max-width: $mobile) {
    font-size: 30px;
  }
}

h2,
.h2 {
  line-height: normal;
  font-size: 30px;
  font-weight: 300;

  @media (max-width: $mobile) {
    font-size: 25px;
  }
}

h3,
.h3 {
  line-height: normal;
  font-size: 25px;
  font-weight: 300;

  @media (max-width: $mobile) {
    font-size: 20px;
  }
}

h4,
.h4 {
  line-height: normal;
  font-size: 20px;
  font-weight: 300;

  @media (max-width: $mobile) {
    font-size: 18px;
  }
}

label {
  display: inline-block;

  .optional {
    font-size: 0.75em;
    opacity: 0.8;
  }

  // CardModal__image uses this
  + input[type='text'],
  + input[type='email'],
  + input[type='password'],
  + select,
  + textarea {
    margin-top: $margin / 3;
  }
}
