@import 'styles/fonts';
@import 'styles/vars';
@import 'styles/reset';

body * {
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  outline: none;

  &.focus-visible {
    box-shadow: 0 0 0 2px rgba($light, 0.9);
  }
}

a,
a:active,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}

@import 'styles/ui';
