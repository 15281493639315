@import 'styles/vars';

.Settings {
  display: flex;
  justify-content: center;
  margin-top: $margin;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    margin-top: 0;
  }

  .left,
  .right {
    width: 50%;
    padding: 0 $margin;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  .left {
    border-right-color: $dark;

    @media (max-width: 800px) {
      border-right-color: transparent;
    }

    h1 {
      margin: 0 0 ($margin * 1.6);
      text-align: center;
    }

    .video {
      margin: 0 auto;
      text-align: center;
    }
  }

  .form {
    width: 100%;
    padding: $margin;

    h2 {
      text-align: center;
    }

    input + label {
      margin-top: $margin;
    }

    input {
      width: 100%;
    }

    .Error {
      margin: 10px 0 0;
    }
  }

  .Button {
    display: block;
    margin: $margin auto 0;
  }

  &__avatar {
    background-color: $dark;
    background-size: cover;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin: 0 auto;
  }

  &__avatar-input {
    display: none;
  }

  &__pdfs {
    display: flex;
    width: 600px;

    @media (max-width: $tablet) {
      width: $mobile - ($margin * 2);
    }

    @media (max-width: $mobile) {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__pdf {
    width: 100%;
    cursor: pointer;

    @media (max-width: $mobile) {
      width: calc(50% - #{$margin} / 4);
    }

    & + & {
      margin-left: $margin / 2;

      @media (max-width: $mobile) {
        margin-left: 0;
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      @media (max-width: $mobile) {
        margin-left: $margin / 2;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      @media (max-width: $mobile) {
        margin-top: $margin;
      }
    }

    img {
      max-width: 100%;
    }

    > div {
      margin-top: 5px;
      text-align: center;
    }
  }
}
