$margin: 30px;
$headerHeight: 60px;
$subHeaderHeight: 30px;

$dark: #e4a232;
$light: #fecc66;
$error: #dc3545;
$errorBackground: #f6b2b8;
$grey: #eee;

$color-primary: #ffcc66;
$color-secondary: #ca9831;

// Got from Google Chrome mobile simulator
$mobile: 425px;
$tablet: 768px;
